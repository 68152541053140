const API_CONFIG = {
   appUrls: {
      loginUrl: 'auth/login',
      getUserDataUrl: 'auth/user',
      logout: 'auth/logout',

      users: {
         editPassword: 'auth/edit_password'
      },

      files: {
         fileImport: 'files/import',
         importExcel: 'files/import_excel',
         downloadFromS3: 'files/download_from_s3',
      },

      edi: {
         readFile: 'edi/read_edi_file'
      },

      dashboard: {
         getOrderData: 'dashboard/get_order_data',
         get_order_totals_details: 'dashboard/get_order_totals_details',
         get_orders_chart_data: 'dashboard/get_orders_chart_data',
         getOrdersStatsWOWLineData: 'dashboard/get_orders_stats_wow_line_data',
         get_orders_stats_data: 'dashboard/get_orders_stats_data',
         get_orders_brands_stats_data: 'dashboard/get_orders_brands_stats_data',
         get_products_stats_data: 'dashboard/get_products_stats_data',
         exportStatsCSV: 'dashboard/export_stats_csv',
      },

      categories: {
         getList: 'categories/list',
         getListExtended: 'categories/list_extended',
         getFirstLevelList: 'categories/first_level_list',
         getItem: 'categories/getCategory',
         createOrUpdate: 'categories/save',
         deleteItem: 'categories/delete',
         toggleValue: 'categories/toggle_value',
         getTree: 'categories/getTree',
         rebuildTree: 'categories/rebuildTree',

         getAmazonData: 'categories/get_amazon_data',
         getAmazonBestSellers: 'categories/get_amazon_best_sellers',
      },

      brands: {
         getList: 'brands/list',
         getItem: 'brands/get',
         createOrUpdate: 'brands/save',
         deleteItem: 'brands/delete',
         toggleValue: 'brands/toggle_value'
      },

      seasons: {
         getList: 'seasons/list',
         getItem: 'seasons/get',
         createOrUpdate: 'seasons/save',
         deleteItem: 'seasons/delete',
         toggleValue: 'seasons/toggle_value'
      },

      catalogues: {
         getList: 'catalogues/list',
         getItem: 'catalogues/get',
         createOrUpdate: 'catalogues/save',
         deleteItem: 'catalogues/delete',
         toggleValue: 'catalogues/toggle_value'
      },

      products: {
         getList: 'products/list',
         getListExtended: 'products/list_extended',
         getListExtendedNZTAsin: 'products/list_extended_nzt_asin',
         getListExtendedNOAsin: 'products/list_extended_no_asin',
         getListExtendedMissingParity: 'products/list_extended_missing_parity',
         getListExtendedNOVendorCodes: 'products/list_extended_no_vendor_codes',
         getItem: 'products/get',
         createOrUpdate: 'products/save',
         deleteItem: 'products/delete',
         toggleValue: 'products/toggle_value',

         importCSVProducts: 'products/import_products_from_csv',
         exportCSV: 'products/export_csv',
         exportExcel: 'products/export_excel',
         exportAsinExcel: 'products/export_asin_excel',
         exportAsinCSV: 'products/export_asin_csv',

         getAmazonAnalysis: 'products/get_amazon_analysis',
      },

      searchProducts: {
         searchProductInCatalogue: 'search_products/search_product_in_catalogue',
         searchProductInCatalogueByEan: 'search_products/search_product_in_catalogue_by_ean',
         searchProductInCatalogueByMpn: 'search_products/search_product_in_catalogue_by_mpn',
         searchProductInCatalogueByTitle: 'search_products/search_product_in_catalogue_by_title',
         lookupAmazonDataByMpnOrAsinOrEanOrTitle: 'search_products/lookup_amazon_data_by_mpn_asin_ean_title',
         lookupAmazonDataByMpnOrEanOrTitle: 'search_products/lookup_amazon_data_by_mpn_ean_title',
         getLookupResults: 'search_products/get_lookup_results',

         searchFromFile: 'search_products/search_from_file',
      },

      amazonMaps: {
         getList: 'amazon_maps/list',
         getListExtended: 'amazon_maps/list_extended',
         getItem: 'amazon_maps/get',
         createOrUpdate: 'amazon_maps/save',
         deleteItem: 'amazon_maps/delete',

         importCSV: 'amazon_maps/import_from_csv',
      },

      productsAmazonData: {
         getItem: 'products_amazon_data/get',
      },

      purchase_orders: {
         getList: 'purchase_orders/list',
         getListExtended: 'purchase_orders/list_extended',
         getListForProgressives: 'purchase_orders/list_for_progressives',
         getYearList: 'purchase_orders/list_years',
         getItem: 'purchase_orders/get',
         deleteItem: 'purchase_orders/delete',
         toggleValue: 'purchase_orders/toggle_value',
         importFromCsv: 'purchase_orders/import',
         importEdiFromAmazon: 'purchase_orders/import_edi_from_amazon',
         importNewFromApi: 'purchase_orders/import_new_from_api',

         getExpiringList: 'purchase_orders/list_expiring',
         getExpiringItem: 'purchase_orders/get_expiring',

         exportList: 'purchase_orders/export_order_list',
         exportOrdersForRIA: 'purchase_orders/export_for_ria',
         exportForPOPivot: 'purchase_orders/export_for_po_pivot',
         exportForRIAWeeklyPivot: 'purchase_orders/export_for_ria_weekly_pivot',

         createProgressives: 'purchase_orders/create_progressives',

         checkWaitingStatus: 'purchase_orders/check_waiting_status',
         updateOrderStatus: 'purchase_orders/update_order_status',
         enqueueDownloadOrders: 'purchase_orders/enqueue_download_orders_by_id',
      },

      purchase_order_lines: {
         getList: 'purchase_order_lines/list',
         getItem: 'purchase_order_lines/get',
         createOrUpdate: 'purchase_order_lines/save',
         deleteItem: 'purchase_order_lines/delete',
         toggleValue: 'purchase_order_lines/toggle_value',
         importFromCsv: 'purchase_order_lines/import',
      },

      order_statuses: {
         getList: 'order_statuses/list',
         getItem: 'order_statuses/get',
      },

      closing_orders: {
         getListExtended: 'closing_purchase_orders/list_extended',
         getItem: 'closing_purchase_orders/get',
         updateOrderStatus: 'closing_purchase_orders/update_status',
         deleteItem: 'closing_purchase_orders/delete',
         deleteItems: 'closing_purchase_orders/delete_items',
         exportOrdersToCsv: 'closing_purchase_orders/export_orders_to_csv',
         exportLinesToCsv: 'closing_purchase_orders/export_lines_to_csv',
      },

      closing_order_statuses: {
         getList: 'closing_order_statuses/list',
         getItem: 'closing_order_statuses/get',
      },

      vendor_orders: {
         createOrUpdate: 'vendor_orders/save',
         getList: 'vendor_orders/list',
         getListExtended: 'vendor_orders/list_extended',
         getYearList: 'vendor_orders/list_years',
         getItem: 'vendor_orders/get',
         deleteItem: 'vendor_orders/delete',
         importFromCsv: 'vendor_orders/import',
      },

      vendor_order_lines: {
         getList: 'vendor_order_lines/list',
         getItem: 'vendor_order_lines/get',
         createOrUpdate: 'vendor_order_lines/save',
         deleteItem: 'vendor_order_lines/delete',
         importFromCsv: 'vendor_order_lines/import',
      },

      specifics: {
         getList: 'specifics/list',
         getItem: 'specifics/get',
         createOrUpdate: 'specifics/save',
         deleteItem: 'specifics/delete',
         toggleValue: 'specifics/toggle_value'
      },

      specificValues: {
         getList: 'specific_values/list',
         getListByParent: 'specific_values/list_by_parent',
         getItem: 'specific_values/get',
         createOrUpdate: 'specific_values/save',
         deleteItem: 'specific_values/delete',
         toggleValue: 'specific_values/toggle_value'
      },

      variations: {
         getList: 'variations/list',
         getItem: 'variations/get',
         createOrUpdate: 'variations/save',
         deleteItem: 'variations/delete',
         toggleValue: 'variations/toggle_value'
      },

      variationValues: {
         getList: 'variation_values/list',
         getListByParent: 'variation_values/list_by_parent',
         getItem: 'variation_values/get',
         createOrUpdate: 'variation_values/save',
         deleteItem: 'variation_values/delete',
         toggleValue: 'variation_values/toggle_value'
      },

      marketplacesCountries: {
         getList: 'marketplaces_countries/list',
      },

      marketplacesLanguages: {
         getList: 'marketplaces_languages/list',
      },

      marketplacesCurrencies: {
         getList: 'marketplaces_currencies/list',
      },

      marketplaces: {
         getList: 'marketplaces/list',
         getActiveList: 'marketplaces/list_active',
         getBasesList: 'marketplaces/list_bases',
         getItem: 'marketplaces/get',
         getConfig: 'marketplaces/get_config',
         setConfig: 'marketplaces/set_config',
         setAPIConfig: 'marketplaces/set_api_config',
         connectionTest: 'marketplaces/connection_test',
         createOrUpdate: 'marketplaces/save',
         deleteItem: 'marketplaces/delete',
         toggleActive: 'marketplaces/toggle_active'
      },

      vendorCodes: {
         getList: 'vendor_codes/list',
         getListExtended: 'vendor_codes/list_extended',
         getParentList: 'vendor_codes/list_parent',
         getItem: 'vendor_codes/get',
         createOrUpdate: 'vendor_codes/save',
         toggleActive: 'vendor_codes/toggle_active',
         deleteItem: 'vendor_codes/delete',
      },

      priceRules: {
         getList: 'price_rules/list',
         getListByMarketplace: 'price_rules/list_by_marketplace',
         getListForMarketplace: 'price_rules/list_for_marketplace',
         getItem: 'price_rules/get',
         createOrUpdate: 'price_rules/save',
         deleteItem: 'price_rules/delete',
         toggleActive: 'price_rules/toggle_active'
      },

      taxRates: {
         getList: 'tax_rates/list',
         getItem: 'tax_rates/get',
         createOrUpdate: 'tax_rates/save',
         deleteItem: 'tax_rates/delete',
         toggleActive: 'tax_rates/toggle_active'
      },

      suppliers: {
         getList: 'suppliers/list',
         getListExtended: 'suppliers/list_extended',
         getItem: 'suppliers/get',
         createOrUpdate: 'suppliers/save',
         emptyItems: 'suppliers/empty',
         emptyItemBrand: 'suppliers/empty_supplier_brand',
         deleteItems: 'suppliers/delete',
         exportStocksSuppliers: 'suppliers/export_stocks_excel',
      },

      customers: {
         getList: 'customers/list',
         getListExtended: 'customers/list_extended',
         getItem: 'customers/get',
         createOrUpdate: 'customers/save',
         deleteItem: 'customers/delete',
      },

      warehouses: {
         getList: 'warehouses/list',
         getListExtended: 'warehouses/list_extended',
         getItem: 'warehouses/get',
         createOrUpdate: 'warehouses/save',
         deleteItems: 'warehouses/delete',
         emptyItems: 'warehouses/empty',
         emptyBrandItems: 'warehouses/empty_brand',
         exportStocksWarehouses: 'warehouses/export_stocks_excel',
      },

      stockWarehouses: {
         getModel: 'stock_warehouses/model',
         getList: 'stock_warehouses/list',
         getListExtended: 'stock_warehouses/list_extended',
         getItem: 'stock_warehouses/get',
         createOrUpdate: 'stock_warehouses/save',
         deleteItems: 'stock_warehouses/delete',
         importFromFile: 'stock_warehouses/import_from_file',
         exportExcel: 'stock_warehouses/export_excel',
      },
      stockSuppliers: {
         getModel: 'stock_suppliers/model',
         getList: 'stock_suppliers/list',
         getListExtended: 'stock_suppliers/list_extended',
         getItem: 'stock_suppliers/get',
         createOrUpdate: 'stock_suppliers/save',
         deleteItems: 'stock_suppliers/delete',
         emptyWarehouses: 'stock_suppliers/empty_warehouses',
         importFromFile: 'stock_suppliers/import_from_file',
         exportExcel: 'stock_suppliers/export_excel',
      },

      progressives: {
         getModel: 'progressives/model',
         getList: 'progressives/list',
         getListExtended: 'progressives/list_extended',
         getTypesList: 'progressives/list_types',
         getGroupsList: 'progressives/list_groups',
         getCategoriesList: 'progressives/list_categories',
         getQuartersList: 'progressives/list_quarters',
         getItem: 'progressives/get',
         createOrUpdate: 'progressives/save',
         duplicateItem: 'progressives/duplicate',
         deleteItems: 'progressives/delete',
         confirmItems: 'progressives/confirm',
         cancelItems: 'progressives/cancel',
         restoreItems: 'progressives/restore',
         mergeItems: 'progressives/merge',
         sendToAmazon: 'progressives/sqs_send_to_amazon',
         importFromFile: 'progressives/import_from_file',
         exportExcel: 'progressives/export_excel',
         exportDetails: 'progressives/export_details',
      },

      supplierConfirmations: {
         getModel: 'supplier_confirmations/model',
         getList: 'supplier_confirmations/list',
         getListExtended: 'supplier_confirmations/list_extended',
         getItem: 'supplier_confirmations/get',
         createOrUpdate: 'supplier_confirmations/save',
         deleteItems: 'supplier_confirmations/delete',
         cancelItems: 'supplier_confirmations/cancel',
         importFromFile: 'supplier_confirmations/import_from_file',
         exportExcel: 'supplier_confirmations/export_excel',
      },
      supplierConfirmationLines: {
         getList: 'supplier_confirmation_lines/list',
         getListExtended: 'supplier_confirmation_lines/list_extended',
         getItem: 'supplier_confirmation_lines/get',
         // createOrUpdate: 'supplier_confirmations/save',
         // deleteItems: 'supplier_confirmations/delete',
         // cancelItems: 'supplier_confirmations/cancel',
      },
      purchaseOrderConfirmationLines: {
         getModel: 'purchase_order_line_confirmations/model',
         importFromFile: 'purchase_order_line_confirmations/import_from_file',
      },
      
      purchaseOrderManualUpdates: {
         getList: 'purchase_order_manual_updates/list',
         getListExtended: 'purchase_order_manual_updates/list_extended',
         getItem: 'purchase_order_manual_updates/get',
         deleteItems: 'purchase_order_manual_updates/delete',
         updateStatus: 'purchase_order_manual_updates/update_status',
      },
      purchaseOrderManualUpdateLines: {
         getList: 'purchase_order_manual_update_lines/list',
         getListExtended: 'purchase_order_manual_update_lines/list_extended',
         getItem: 'purchase_order_manual_update_lines/get',
         getModel: 'purchase_order_manual_update_lines/model',
         importFromFile: 'purchase_order_manual_update_lines/import_from_file',
      },

      uniqueFiles: {
         getList: 'unique_files/list',
         getListExtended: 'unique_files/list_extended',
         getLinesExtended: 'unique_files/list_lines_extended',
         exportFileRIA: 'unique_files/export_ria',
         exportFileSEASONAL: 'unique_files/export_seasonal',
      },

      analysisEanAsin: {
         getModel: 'analysis_ean_asin/model',
         getList: 'analysis_ean_asin/list',
         getListExtended: 'analysis_ean_asin/list_extended',
         getItem: 'analysis_ean_asin/get',
         createOrUpdate: 'analysis_ean_asin/save',
         deleteItems: 'analysis_ean_asin/delete',
         importFromFile: 'analysis_ean_asin/import_from_file',
         addToAnalysisQueue: 'analysis_ean_asin/add_to_analysis_queue',
         exportCodeAnalysisResults: 'analysis_ean_asin/export_code_analysis_results',
         exportAsinAnalysisResults: 'analysis_ean_asin/export_asin_analysis_results',
      },
      analysisAsinAvailability: {
         getModel: 'analysis_asin_availability/model',
         analyzeFile: 'analysis_asin_availability/analyze_file',
      },

      chargebacks: {
         importFile: 'chargebacks/import_file',
      },

      dataProcessing: {
         processGroupSizes: 'data_processing/process_group_sizes',
         searchProductsByAsin: 'data_processing/search_products_by_asin',
      },

      getEbayOfficialTimeUrl: 'api/connectors/ebay/getEbayOfficialTime',

      getTrafficDataUrl: 'api/connectors/ebay/getTrafficData',
      getSellingDataUrl: 'api/connectors/ebay/getSellingData',

      getTrafficSitesUrl: 'api/connectors/ebay/getTrafficSites',
      getTrafficTotalsUrl: 'api/connectors/ebay/getTrafficTotals',
      getTrafficTotalHitsUrl: 'api/connectors/ebay/getTrafficTotalHits',
      getTrafficCategoriesUrl: 'api/connectors/ebay/getTrafficCategories',
      getTrafficDatatableUrl: 'api/connectors/ebay/getTrafficDatatable',

      getCategoriesTotalsUrl: 'api/connectors/ebay/getCategoriesTotals',
      getCategoriesDatatableUrl: 'api/connectors/ebay/getCategoriesDatatable',
      getCategoriesDetailsTableUrl: 'api/connectors/ebay/getCategoriesDetailsTable',
      getSuggestedCategoryUrl: 'api/connectors/ebay/getSuggestedCategory',

      getPricingTotalsUrl: 'api/connectors/ebay/getPricingTotals',
      getPricingDatatableUrl: 'api/connectors/ebay/getPricingDatatable',
      getPricingDetailsTableUrl: 'api/connectors/ebay/getPricingDetailsTable',
      getCompetitorPricesUrl: 'api/connectors/ebay/getCompetitorPrices',

      getSellingTotalsUrl: 'api/connectors/ebay/getSellingTotals',
      getSellingDatatableUrl: 'api/connectors/ebay/getSellingDatatable',
      getSellingDetailsTableUrl: 'api/connectors/ebay/getSellingDetailsTable',
      getSellingNotSoldTableUrl: 'api/connectors/ebay/getSellingNotSoldTable',


      test: {
         importFile: 'test/import_file',
         updateOrderStatus: 'test/update_po_status',
         sendClosingPOUpdates: 'test/send_closing_po_updates',
      },
   }
};

export default API_CONFIG;
