import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

import languageManager from '../../../../services/languageManager'
import * as purchaseOrdersActions from '../../../orders/purchase_orders/store/actions/purchase_orders'
import { PurchaseOrderTypeEnum, ProgressiveQuarterEnum } from '../../../../shared/enums'

import { Form, Input, InputNumber, Select, Button, Row, Col, notification, List, Space, Typography } from 'antd'
import { CheckOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons'

const FormEditProgressive = (props) => {
    const [layout, setLayout] = useState(props.layout)
    // const [customerId, setCustomerId] = useState(props.customerId)
    const [values, setValues] = useState(props.values)
    const [complements, setComplements] = useState(props.complements)

    const [newCategoryButton, setNewCategoryButton] = useState(true)
    const [newCategoryInput, setNewCategoryInput] = useState(false)
    const [newSupplierButton, setNewSupplierButton] = useState(true)
    const [newSupplierInput, setNewSupplierInput] = useState(false)
    const [newCustomerButton, setNewCustomerButton] = useState(true)
    const [newCustomerInput, setNewCustomerInput] = useState(false)

    const [selectedProgressiveType, setSelectedProgressiveType] = useState(null)
    const [purchaseOrdersList, setPurchaseOrdersList] = useState([])
    const [showPOList, setShowPOList] = useState(false)
    const [showQuarterList, setShowQuarterList] = useState(false)
    const [showWarehousesList, setShowWarehousesList] = useState(false)
    const [showSuppliersList, setShowSuppliersList] = useState(false)
    const [loadingPO, setLoadingPO] = useState(false)

    const [form] = Form.useForm()
    const { Text } = Typography
    const { Option } = Select

    useEffect(() => {
        console.log(props)
        // setCustomerId(props.customerId)
        setValues(props.values)
        if (_.isEmpty(props.values)) {
            form.resetFields()
            setNewCategoryButton(true)
            setNewSupplierButton(true)
            setNewCustomerButton(true)
            selectTypeHandler(null)
        } else {
            selectTypeHandler(props.values.type, false, props.values.purchase_orders_ids)
            form.setFieldsValue(props.values)
            if (!_.isNil(props.values.category)) setNewCategoryButton(false)
            if (!_.isNil(props.values.supplier_id)) setNewSupplierButton(false)
            if (!_.isNil(props.values.customer_id)) setNewCustomerButton(false)
        }
    }, [props.values])

    const loadAllOrders = () => {
        selectTypeHandler(selectedProgressiveType, true)
    }
    const loadOrdersList = (progressiveType, fullList, selectedIds) => {
        setLoadingPO(true)
        return purchaseOrdersActions.getListForProgressives(progressiveType, fullList, selectedIds)
            .then(response => {
                setPurchaseOrdersList(response.data)
                setLoadingPO(false)
            }).catch((e) => {
                notification.error({
                    message: languageManager.getMessage(props.commonState, 'notification.warning.title'),
                    description: e.error ? (
                        <List
                            size="small"
                            split={false}
                            dataSource={e.error}
                            renderItem={item => <List.Item>{item}</List.Item>}
                        />
                    ) : languageManager.getMessage(props.commonState, 'notification.error.serverError'),
                    duration: 0
                })
                setLoadingPO(false)
                if (props.commonState.debug) console.log(e)
            });
    }

    const submitForm = (values) => {
        // values.customer_id = customerId
        props.submitForm(values)
    }

    const onTypeSelect = (value) => {
        selectTypeHandler(value)
    }
    const onTypeClear = () => {
        selectTypeHandler(null)
    }
    const selectTypeHandler = (progressiveType, fullList = false, selectedIds = []) => {
        switch (progressiveType) {
            case PurchaseOrderTypeEnum.SEASONAL:
                setSelectedProgressiveType(progressiveType)
                setShowQuarterList(true)
                setShowPOList(false)
                setShowWarehousesList(false)
                setShowSuppliersList(true)
                setPurchaseOrdersList([])
                break
            case PurchaseOrderTypeEnum.RIA:
                setSelectedProgressiveType(progressiveType)
                setShowQuarterList(false)
                setShowPOList(true)
                setShowWarehousesList(false)
                setShowSuppliersList(true)
                loadOrdersList(progressiveType, fullList, selectedIds)
                break
            case PurchaseOrderTypeEnum.STOCK:
                setSelectedProgressiveType(progressiveType)
                setShowQuarterList(false)
                setShowPOList(true)
                setShowWarehousesList(true)
                setShowSuppliersList(false)
                loadOrdersList(progressiveType, fullList, selectedIds)
                break
            default:
                setSelectedProgressiveType(null)
                setShowQuarterList(false)
                setShowPOList(false)
                setShowWarehousesList(false)
                setShowSuppliersList(true)
                setPurchaseOrdersList([])
                break
        }
    }

    const onCategorySelect = (value, option) => {
        setNewCategoryButton(false)
    }
    const onCategoryClear = () => {
        setNewCategoryButton(true)
    }
    const newCategoryButtonHandler = (value) => {
        setNewCategoryInput(value)
        if (value === false) {
            form.setFieldsValue({ category: null })
        } else {
            form.setFieldsValue({ category_new: null })
        }
    }

    const onSupplierSelect = (value, option) => {
        setNewSupplierButton(false)
    }
    const onSupplierClear = () => {
        setNewSupplierButton(true)
    }
    const newSupplierButtonHandler = (value) => {
        setNewSupplierInput(value)
        if (value === false) {
            form.setFieldsValue({ supplier_id: null })
        } else {
            form.setFieldsValue({ supplier_new: null })
        }
    }

    const onCustomerSelect = (value, option) => {
        setNewCustomerButton(false)
    }
    const onCustomerClear = () => {
        setNewCustomerButton(true)
    }
    const newCustomerButtonHandler = (value) => {
        setNewCustomerInput(value)
        if (value === false) {
            form.setFieldsValue({ customer_id: null })
        } else {
            form.setFieldsValue({ customer_new: null })
        }
    }

    return (
        <Form
            form={form}
            {...layout}
            id="FormEditProgressive"
            onFinish={submitForm}
        >
            {
                !_.isNil(props.values) && !_.isNil(props.values.id) ? (
                    <Form.Item
                        label={languageManager.getMessage(props.commonState, 'component.label.progressive')}
                        name="progressive"
                    >
                        <Input disabled />
                    </Form.Item>
                ) : null
            }
            <Form.Item
                label={languageManager.getMessage(props.commonState, 'component.label.vendor_code')}
                name="vendor_code_id"
                rules={[{ required: true }]}
            >
                <Select
                    showSearch
                    allowClear={true}
                    style={{ width: 200 }}
                    placeholder="Seleziona il VendorCode"
                    optionFilterProp="children"
                >
                    {
                        complements.vendorCodesList.map((vendorcode) => {
                            return <Option key={vendorcode.id} value={vendorcode.id}>{vendorcode.code}</Option>
                        })
                    }
                </Select>
            </Form.Item>
            <Form.Item
                label={languageManager.getMessage(props.commonState, 'component.label.type')}
                name="type"
                rules={[{ required: true }]}
            >
                <Select
                    showSearch
                    allowClear={true}
                    style={{ width: 200 }}
                    placeholder="Seleziona il tipo"
                    optionFilterProp="children"
                    onSelect={onTypeSelect}
                    onClear={onTypeClear}
                >
                    {
                        complements.typesList.map((type) => {
                            return <Option key={type.id} value={type.id}>{type.name}</Option>
                        })
                    }
                </Select>
            </Form.Item>
            <Form.Item
                label={languageManager.getMessage(props.commonState, 'component.label.country')}
                name="country_id"
                rules={[{ required: true }]}
            >
                <Select
                    showSearch
                    allowClear={true}
                    style={{ width: 200 }}
                    placeholder="Seleziona il paese"
                    optionFilterProp="children"
                >
                    {
                        complements.countriesList.map((country) => {
                            return <Option key={country.id} value={country.id}>{country.t_name}</Option>
                        })
                    }
                </Select>
            </Form.Item>
            <Form.Item
                label={languageManager.getMessage(props.commonState, 'component.label.group')}
                name="group"
                rules={[{ required: true }]}
            >
                <Select
                    showSearch
                    allowClear={true}
                    style={{ width: 200 }}
                    placeholder="Seleziona il gruppo"
                    optionFilterProp="children"
                >
                    {
                        complements.groupsList.map((group) => {
                            return <Option key={group.id} value={group.id}>{group.name}</Option>
                        })
                    }
                </Select>
            </Form.Item>
            {
                showQuarterList ? (
                    <Form.Item
                        label={languageManager.getMessage(props.commonState, 'component.label.quarter')}
                        name="quarter"
                        rules={[{ required: true }]}
                    >
                        <Select
                            showSearch
                            allowClear={true}
                            style={{ width: 200 }}
                            placeholder="Seleziona il trimestre"
                            // optionFilterProp='children'
                            filterOption={(input, option) =>
                                option.orderNumber.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {
                                complements.quartersList.map((quarter) => {
                                    return <Option key={quarter.key} value={quarter.id}>{quarter.name}</Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                ) : null
            }
            {
                showPOList ? (
                    <Form.Item
                        label={
                            <Space direction='horizontal' size='small'>
                                <Text type='danger'>*</Text>
                                {languageManager.getMessage(props.commonState, 'component.label.related_purchase_orders')}
                            </Space>}
                    >
                        <Row gutter={4}>
                            <Col>
                                <Form.Item
                                    name="purchase_orders_ids"
                                    noStyle
                                    rules={[{ required: true }]}
                                >
                                    <Select
                                        showSearch
                                        mode='multiple'
                                        allowClear={true}
                                        style={{ width: 200 }}
                                        placeholder="Seleziona gli ordini associati"
                                        // optionFilterProp='children'
                                        filterOption={(input, option) =>
                                            option.orderNumber.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        loading={loadingPO}
                                        disabled={loadingPO}
                                    >
                                        {
                                            purchaseOrdersList.map((order) => {
                                                return (
                                                    <Option key={order.id} value={order.id} orderNumber={order.purchase_order_number}>
                                                        <Space direction='vertical' size={0}>
                                                            <Space direction='horizontal' size='small'>
                                                                <Text strong>{order.purchase_order_number}</Text>
                                                                <Text>{'(' + order.vendor_code + ')'}</Text>
                                                            </Space>
                                                            <Text italic>{order.order_date_human}</Text>
                                                        </Space>
                                                    </Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col>
                                <Button type='primary' onClick={loadAllOrders} loading={loadingPO}>Carica tutti gli ordini</Button>
                            </Col>
                        </Row >
                    </Form.Item>) : null
            }            
            {/* <Form.Item
                label={
                    <Space direction='horizontal' size='small'>
                        {languageManager.getMessage(props.commonState, 'component.label.category')}
                    </Space>
                }
            >
                {
                    newCategoryInput ? (
                        <Row gutter={4}>
                            <Col>
                                <Form.Item
                                    name="category_new"
                                    noStyle
                                >
                                    <Input style={{ width: 200 }} />
                                </Form.Item>
                            </Col>
                            <Col>
                                <Button type='danger' onClick={() => newCategoryButtonHandler(false)}><CloseOutlined /></Button>
                            </Col>
                        </Row >
                    ) : (
                        <Row gutter={4}>
                            <Col>
                                <Form.Item
                                    name="category"
                                    noStyle
                                >
                                    <Select
                                        showSearch
                                        allowClear={true}
                                        style={{ width: 200 }}
                                        placeholder="Seleziona la categoria"
                                        optionFilterProp="children"
                                        onSelect={onCategorySelect}
                                        onClear={onCategoryClear}
                                    >
                                        {
                                            complements.categoriesList.map((category) => {
                                                return <Option key={category.key} value={category.id}>{category.name}</Option>
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col>
                                {
                                    newCategoryButton ? (
                                        <Button type='success' onClick={() => newCategoryButtonHandler(true)}><PlusOutlined /></Button>
                                    ) : null
                                }
                            </Col>
                        </Row >
                    )
                }
            </Form.Item > */}
            <Form.Item
                label={languageManager.getMessage(props.commonState, 'component.filter.brand')}
                name="brand_id"
                rules={[{ required: true }]}
            >
                <Select
                    showSearch
                    allowClear={true}
                    style={{ width: 200 }}
                    placeholder="Seleziona il Brand"
                    optionFilterProp="children"
                >
                    {
                        complements.brandsList.map((brand) => {
                            return <Option key={brand.id} value={brand.id}>{brand.name}</Option>
                        })
                    }
                </Select>
            </Form.Item>
            {
                showWarehousesList ? (
                    <Form.Item
                        label={languageManager.getMessage(props.commonState, 'component.label.warehouse')}
                        name="warehouse_id"
                        rules={[{ required: true }]}
                    >
                        <Select
                            showSearch
                            allowClear={true}
                            style={{ width: 200 }}
                            placeholder="Seleziona il Magazzino"
                            optionFilterProp="children"
                        >
                            {
                                complements.warehousesList.map((warehouse) => {
                                    return <Option key={warehouse.id} value={warehouse.id}>{warehouse.name}</Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                ) : null
            }
            {
                showSuppliersList ? (
                    <Form.Item
                        label={
                            <Space direction='horizontal' size='small'>
                                <Text type='danger'>*</Text>
                                {languageManager.getMessage(props.commonState, 'component.filter.supplier')}
                            </Space>
                        }
                    >
                        {
                            newSupplierInput ? (
                                <Row gutter={4}>
                                    <Col>
                                        <Form.Item
                                            name="supplier_new"
                                            noStyle
                                            rules={[{ required: true }]}
                                        >
                                            <Input style={{ width: 200 }} />
                                        </Form.Item>
                                    </Col>
                                    <Col>
                                        <Button type='danger' onClick={() => newSupplierButtonHandler(false)}><CloseOutlined /></Button>
                                    </Col>
                                </Row >
                            ) : (
                                <Row gutter={4}>
                                    <Col>
                                        <Form.Item
                                            name="supplier_id"
                                            noStyle
                                            rules={[{ required: true }]}
                                        >
                                            <Select
                                                showSearch
                                                allowClear={true}
                                                style={{ width: 200 }}
                                                placeholder="Seleziona il fornitore"
                                                optionFilterProp="children"
                                                onSelect={onSupplierSelect}
                                                onClear={onSupplierClear}
                                            >
                                                {
                                                    complements.suppliersList.map((supplier) => {
                                                        return <Option key={supplier.id} value={supplier.id}>{supplier.name}</Option>
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col>
                                        {
                                            newSupplierButton ? (
                                                <Button type='success' onClick={() => newSupplierButtonHandler(true)}><PlusOutlined /></Button>
                                            ) : null
                                        }
                                    </Col>
                                </Row >
                            )
                        }
                    </Form.Item>
                ) : null
            }
            <Form.Item
                label={
                    <Space direction='horizontal' size='small'>
                        <Text type='danger'>*</Text>
                        {languageManager.getMessage(props.commonState, 'component.filter.customer')}
                    </Space>
                }
            >
                {
                    newCustomerInput ? (
                        <Row gutter={4}>
                            <Col>
                                <Form.Item
                                    name="customer_new"
                                    noStyle
                                    rules={[{ required: true }]}
                                >
                                    <Input style={{ width: 200 }} />
                                </Form.Item>
                            </Col>
                            <Col>
                                <Button type='danger' onClick={() => newCustomerButtonHandler(false)}><CloseOutlined /></Button>
                            </Col>
                        </Row >
                    ) : (
                        <Row gutter={4}>
                            <Col>
                                <Form.Item
                                    name="customer_id"
                                    noStyle
                                    rules={[{ required: true }]}
                                >
                                    <Select
                                        showSearch
                                        allowClear={true}
                                        style={{ width: 200 }}
                                        placeholder="Seleziona il cliente"
                                        optionFilterProp="children"
                                        onSelect={onCustomerSelect}
                                        onClear={onCustomerClear}
                                    >
                                        {
                                            complements.customersList.map((customer) => {
                                                return <Option key={customer.id} value={customer.id}>{customer.name}</Option>
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col>
                                {
                                    newCustomerButton ? (
                                        <Button type='success' onClick={() => newCustomerButtonHandler(true)}><PlusOutlined /></Button>
                                    ) : null
                                }
                            </Col>
                        </Row >
                    )
                }
            </Form.Item>
            <Form.Item
                label={languageManager.getMessage(props.commonState, 'component.label.requested_quantity')}
                name="requested_quantity"
                rules={[{ required: true }]}
            >
                <InputNumber min={0} step={1}
                    style={{ width: 200 }} />
            </Form.Item>
            <Form.Item
                label={languageManager.getMessage(props.commonState, 'component.label.requested_wsp')}
                name="requested_wsp"
                rules={[{ required: true }]}
            >
                <InputNumber min={0} step={1} addonAfter="€"
                    style={{ width: 200 }} />
            </Form.Item>
            <Form.Item
                label={languageManager.getMessage(props.commonState, 'component.label.conditions_buy')}
                name="conditions_buy"
            >
                <Input />
            </Form.Item>
            <Form.Item
                label={languageManager.getMessage(props.commonState, 'component.label.conditions_sell')}
                name="conditions_sell"
            >
                <Input />
            </Form.Item>
            <Form.Item name="id" noStyle><Input type="hidden" /></Form.Item>
        </Form >
    )
}

const mapStateToProps = state => {
    return {
        commonState: state.commonState,
    }
}

export default connect(mapStateToProps)(FormEditProgressive)