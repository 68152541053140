import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import Moment from 'moment'
import 'moment-timezone'

import { OrderStatusActionEnum, PurchaseOrderTypeEnum, PurchaseOrderGroupEnum } from '../../../shared/enums.ts';

import { Table, Select, Button, Space, notification, Tooltip, Card, Collapse, Row, Col, Typography, List, Modal } from 'antd';
import { InfoCircleOutlined, DownloadOutlined, UploadOutlined, QuestionCircleOutlined } from '@ant-design/icons';
// import Highlighter from 'react-highlight-words';
import { DeleteOutlined, EditOutlined, CheckCircleOutlined, CheckOutlined, CloseOutlined, DoubleLeftOutlined, RetweetOutlined, EyeOutlined } from '@ant-design/icons';

import languageManager from '../../../services/languageManager';

import * as storeActions from './store/actions/purchase_orders';
import * as progressivesActions from '../../operations/progressives/store/actions/progressives'

import { startLoading, endLoading } from '../../../shared/utility';
import TableFilters from '../../common/components/TableFilters';
import DrawerEditProgressive from '../../operations/progressives/components/DrawerEditProgressive'

import Loader from '../../common/PageElements/Loader';
import PageHeader from '../../common/PageElements/PageHeader'
import DropdownMenu from '../../common/components/DropdownMenu';
import { updateObject } from '../../../shared/utility'

class PurchaseOrdersList extends Component {
   state = {
      pageLoader: true,
      loaders: {
         downloadEDI: false,
      },
      tableLoader: false,
      loadersDownload: [],
      importBox: false,
      importLoader: true,
      itemList: [],
      fullListIds: [],
      yearsList: [],
      marketplacesList: [],
      searchText: '',
      searchedColumn: '',
      componentLanguage: this.props.authState.defaultLanguage,
      searchYear: Moment().year(),
      poPaginationParams: {
         current_page: 1,
         per_page: 100,
         total: 0,
      },
      selectedRowKeys: [],
      multipleActionLoader: false,
      multipleActionList: [
         {
            action: OrderStatusActionEnum.ACCEPT.toString(),
            name: 'Accetta ordini selezionati'
         },
         {
            action: OrderStatusActionEnum.REFUSE.toString(),
            name: 'Rifiuta ordini selezionati'
         },
         {
            action: OrderStatusActionEnum.PROCESS.toString(),
            name: 'Conferma ordini selezionati'
         },
         {
            // action: 'create_progressive_for_orders',
            action: 'auto_create_progressives_for_orders',
            name: 'Crea progressivi per gli ordini selezionati'
         },
         {
            action: 'download_amazon_updates',
            name: 'Accoda aggiornamento Amazon'
         },
      ],
      multipleExportLoader: false,
      multipleExportList: [
         {
            action: 'export_csv',
            name: 'CSV'
         },
         {
            action: 'export_ria',
            name: 'RIA'
         },
         {
            action: 'export_po_pivot',
            name: 'PO pivot'
         },
         {
            action: 'export_ria_weekly_pivot',
            name: 'RIA weekly'
         },
      ],
      checkWaitingStatus: false,
      feedback: {},
      searchOrderInput: null,

      filters: [],
      // filtersValues: {},
      appliedFilters: [],

      progressivesComplements: {},
      showCreateProgressiveDrawer: false,
      createProgressiveItem: {},
   };

   getList = (paginationParams, filters) => {
      // asyncronous calls
      console.log(filters);
      this.setState({ tableLoader: true })
      return storeActions.getListExtended(paginationParams, filters)
         .then(response => {
            console.log(response);
            try {
               let updatedFilters = []
               for (let filter of response.list_filters) {
                  if (filter.type === 'date') {
                     if (filter.value) {
                        filter.value = Moment(filter.value, 'YYYY-MM-DD');
                     }
                  }
                  updatedFilters.push(filter);
               }

               this.setState({
                  itemList: response.data.data,
                  fullListIds: response.full_list_ids,
                  poPaginationParams: {
                     current_page: response.data.current_page,
                     per_page: response.data.per_page,
                     total: response.data.total
                  },

                  filters: updatedFilters,
                  appliedFilters: response.applied_filters,

                  tableLoader: false,
                  loaders: endLoading(this.state.loaders, 'downloadEDI')
               });
            } catch (e) {
               this.setState({ tableLoader: false })
               notification.error({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
               })
               if (this.props.commonState.debug) console.log(e);
            }

         }).catch((e) => {
            this.setState({ tableLoader: false })
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }
   updateList = () => {
      this.getList(this.state.poPaginationParams, this.state.filtersValues);
   }

   getComplements = () => {
      return storeActions.getPurchaseOrdersComplements()
         .then(response => {
            console.log(response);
            try {
               let list = []
               _.forEach(response.statusesList, function (value) {
                  list.push({ id: value.id, name: value.t_name })
               })
               this.setState({
                  complementsResponse: response,
                  yearsList: response.yearsList,
                  marketplacesList: response.marketplacesList,
               });
            } catch (e) {
               notification.error({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
               })
               if (this.props.commonState.debug) console.log(e);
            }

         }).catch((e) => {
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   getProgressivesComplements = () => {
      return progressivesActions.getComplements()
         .then(response => {
            console.log(response);
            try {
               const progressivesComplements = {
                  typesList: response.typesList,
                  groupsList: response.groupsList,
                  categoriesList: response.categoriesList,
                  quartersList: response.quartersList,
                  brandsList: response.brandsList,
                  suppliersList: response.suppliersList,
                  customersList: response.customersList,
               }
               this.setState({
                  // complementsResponse: response,
                  progressivesComplements: progressivesComplements
               });
            } catch (e) {
               notification.error({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
               })
               if (this.props.commonState.debug) console.log(e);
            }
         }).catch((e) => {
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: e.error ? (
                  <List
                     size="small"
                     split={false}
                     dataSource={e.error}
                     renderItem={item => <List.Item>{item}</List.Item>}
                  />
               ) : languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               duration: 0
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   componentDidMount() {
      console.log('2022-03-31 22:00:00')
      console.log(Moment.utc('2022-03-31 22:00:00').tz('Europe/Rome').format('L LTS'))
      console.log(Moment.utc('2022-03-31 22:00:00').format('d/m/Y H:i:s'))
      console.log(Moment.utc('2022-03-31 22:00:00').tz('Europe/Rome'))
      var initialFilters = {}
      if (this.props.match.params.status) {
         switch (this.props.match.params.status) {
            case 'pending':
               initialFilters.po_status_id = [1]
               break
            case 'accepted':
               initialFilters.po_status_id = [2, 3]
               break
         }
      }
      Promise.all([
         this.getList(this.state.poPaginationParams, initialFilters),
         this.getComplements(),
         this.getProgressivesComplements()
      ]).then(() => {
         this.setState({
            filtersValues: initialFilters,
            pageLoader: false
         });
      });
   }

   componentDidUpdate = (prevProps, prevState) => {
      if (!_.isEqual(prevProps.match.params.status, this.props.match.params.status)) {
         this.setState({ pageLoader: true });
         var initialFilters = {}
         if (this.props.match.params.status) {
            switch (this.props.match.params.status) {
               case 'pending':
                  initialFilters.po_status_id = [1]
                  break
               case 'accepted':
                  initialFilters.po_status_id = [2, 3]
                  break
            }
         }
         Promise.all([
            this.getList(this.state.poPaginationParams, initialFilters),
            this.getComplements()
         ]).then(() => {
            this.setState({
               filtersValues: initialFilters,
               pageLoader: false
            });
         });
      }
   }

   importEdiFromAmazon = () => {
      this.setState({ loaders: startLoading(this.state.loaders, 'downloadEDI') });
      console.log("Import");
      storeActions.importEdiFromAmazon()
         .then(response => {
            console.log(response);
            try {
               this.getList(this.state.poPaginationParams, this.state.filtersValues);
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.success.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.confirm.downloadOk'),
                  icon: <CheckCircleOutlined className="color-green-I" />,
               })
            } catch (e) {
               notification.error({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
               })
               if (this.props.commonState.debug) console.log(e);
            }

         }).catch((e) => {
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
            })
            if (this.props.commonState.debug) console.log(e);
         });

   }

   importNewFromApi = () => {
      this.setState({ loaders: startLoading(this.state.loaders, 'downloadAPI') });
      console.log("Import from API");
      storeActions.importNewFromApi()
         .then(response => {
            console.log(response);
            try {
               this.getList(this.state.poPaginationParams, this.state.filtersValues);
               this.setState({ loaders: endLoading(this.state.loaders, 'downloadAPI') });
               notification.success({
                  message: languageManager.getMessage(this.props.commonState, 'notification.success.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.confirm.downloadOk'),
               })
            } catch (e) {
               this.setState({ loaders: endLoading(this.state.loaders, 'downloadAPI') });
               notification.error({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
               })
               if (this.props.commonState.debug) console.log(e);
            }

         }).catch((e) => {
            this.setState({ loaders: endLoading(this.state.loaders, 'downloadAPI') });
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   checkWaitingStatusHandler = (orderIds, newStatus) => {
      storeActions.checkWaitingStatus(orderIds)
         .then(response => {
            if (response.data) {
               this.orderStatusHandler(this.state.selectedRowKeys, newStatus)
            } else {
               Modal.confirm({
                  title: languageManager.getMessage(this.props.commonState, 'common.accept_orders'),
                  icon: <QuestionCircleOutlined />,
                  content: languageManager.getMessage(this.props.commonState, 'component.purchase_orders.warning.notAllOrdersInWaitingStatus'),
                  okText: languageManager.getMessage(this.props.commonState, 'common.confirm'),
                  onOk: () => this.orderStatusHandler(this.state.selectedRowKeys, newStatus)
               })
            }
         }).catch((e) => {
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }
   orderStatusHandler = (orderIds, newStatus) => {
      this.setState({ tableLoader: true });
      storeActions.updateOrderStatus(orderIds, newStatus)
         .then(response => {
            console.log(response);
            try {
               this.getList(this.state.poPaginationParams, this.state.filtersValues);
               if (!_.isNil(response.human) && response.status === 'ok') {
                  notification.success({
                     message: languageManager.getMessage(this.props.commonState, 'notification.success.title'),
                     description: (
                        <List
                           size="small"
                           split={false}
                           dataSource={response.human}
                           renderItem={item => <List.Item>{item}</List.Item>}
                        />
                     ),
                     duration: 0
                  })
               } else if (!_.isNil(response.human)) {
                  notification.warning({
                     message: languageManager.getMessage(this.props.commonState, 'notification.success.title'),
                     description: (
                        <List
                           size="small"
                           split={false}
                           dataSource={response.human}
                           renderItem={item => <List.Item>{item}</List.Item>}
                        />
                     ),
                     duration: 0
                  })
               }
            } catch (e) {
               this.setState({ tableLoader: false });
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            }

         }).catch((e) => {
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
            })
            this.setState({ tableLoader: false });
            if (this.props.commonState.debug) console.log(e);
         });
   }

   orderDownloadHandler = (orderIds) => {
      this.setState({ multipleActionLoader: true, loadersDownload: startLoading(this.state.loadersDownload, orderIds) });
      storeActions.enqueueDownloadOrders(orderIds)
         .then(response => {
            console.log(response);
            if (!_.isNil(response.human) && response.status === 'ok') {
               notification.success({
                  message: languageManager.getMessage(this.props.commonState, 'notification.success.title'),
                  description: (
                     <List
                        size="small"
                        split={false}
                        dataSource={response.human}
                        renderItem={item => <List.Item>{item}</List.Item>}
                     />
                  ),
                  duration: 0
               })
            }
            this.setState({ multipleActionLoader: false, loadersDownload: endLoading(this.state.loadersDownload, orderIds) });
         }).catch((e) => {
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
            })
            this.setState({ multipleActionLoader: false, loadersDownload: endLoading(this.state.loadersDownload, orderIds) });
            if (this.props.commonState.debug) console.log(e);
         });
   }

   exportOrdersHandler = () => {
      this.setState({ multipleExportLoader: true });
      let now = Moment().format('YYYY.MM.DD-HH.mm.ss');
      let filename = now + '_export_orders.csv';
      console.log("Export orders", filename, this.state.selectedRowKeys);
      storeActions.exportOrders(filename, this.state.selectedRowKeys)
         .then(response => {
            try {
               console.log(response);
               this.setState({ multipleExportLoader: false });
               notification.succes({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.confirm.exportOk'),
               })
            } catch (e) {
               this.setState({ multipleExportLoader: false });
               notification.error({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
               })
            }

         }).catch((e) => {
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
            })
            this.setState({ multipleExportLoader: false });
            if (this.props.commonState.debug) console.log(e);
         });
   }
   exportForRIAHandler = () => {
      this.setState({ multipleExportLoader: true });
      let now = Moment().format('YYYY.MM.DD-HH.mm.ss');
      let filename = now + '_RIA.zip';
      storeActions.exportForRIA(filename, this.state.selectedRowKeys)
         .then(response => {
            try {
               console.log(response);
               this.setState({ multipleExportLoader: false });
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.confirm.exportOk'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            }

         }).catch((e) => {
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            this.setState({ multipleExportLoader: false });
            if (this.props.commonState.debug) console.log(e);
         });
   }
   exportForPOPivotHandler = () => {
      this.setState({ multipleExportLoader: true });
      let now = Moment().format('YYYY.MM.DD-HH.mm.ss');
      let filename = now + '_ORDERS.zip';
      storeActions.exportForPOPivot(filename, this.state.selectedRowKeys)
         .then(response => {
            console.log(response);
            this.setState({ multipleExportLoader: false });
            notification.success({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.confirm.exportOk'),
            })

         }).catch((e) => {
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
            })
            this.setState({ multipleExportLoader: false });
            if (this.props.commonState.debug) console.log(e);
         });
   }
   exportForRIAWeeklyPivot = () => {
      this.setState({ multipleExportLoader: true });
      let now = Moment().format('YYYY.MM.DD-HH.mm.ss');
      let filename = now + '_RIA_WEEKLY_PIVOT.csv';
      storeActions.exportForRIAWeeklyPivot(filename, this.state.selectedRowKeys)
         .then(response => {
            console.log(response);
            this.setState({ multipleExportLoader: false });
            notification.success({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.confirm.exportOk'),
            })

         }).catch((e) => {
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
            })
            this.setState({ multipleExportLoader: false });
            if (this.props.commonState.debug) console.log(e);
         });
   }

   createProgressivesHandler = (type, country, group, vendorcodeId, fcCodeId) => {
      const typeName = PurchaseOrderTypeEnum[type]
      const countryName = country
      const groupName = PurchaseOrderGroupEnum[group]
      this.setState({ multipleActionLoader: true });
      let now = Moment().format('YYYY.MM.DD-HH.mm.ss');
      let filename = now + '_PROGRESSIVES_' + typeName + '_' + countryName + '_' + groupName + '.zip';
      storeActions.createProgressives(type, country, group, vendorcodeId, fcCodeId, this.state.selectedRowKeys, filename)
         .then(response => {
            try {
               console.log(response);
               this.setState({ multipleActionLoader: false });
               notification.success({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.confirm.exportOk'),
               })
            } catch (e) {
               notification.error({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
               })
            }

         }).catch((e) => {
            console.log(e);
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: e.human ? (
                  <List
                     size="small"
                     split={false}
                     dataSource={e.human}
                     renderItem={item => <List.Item>{item}</List.Item>}
                  />
               ) : languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               duration: 0
            })
            this.setState({ multipleActionLoader: false });
            if (this.props.commonState.debug) console.log(e);
         });
   }

   onSelectChange = (selectedRowKeys) => {
      this.setState({ selectedRowKeys: selectedRowKeys });
   };
   selectAllHandler = () => {
      if (this.state.selectedAll) {
         this.setState({
            selectedAll: false,
            selectedRowKeys: [],
         });
      }
      else {
         this.setState({
            selectedAll: true,
            selectedRowKeys: this.state.fullListIds,
         });
      }
   }
   multipleActionHandler = (value) => {
      const props = value.item.props
      switch (props.action) {
         case OrderStatusActionEnum.ACCEPT.toString():
            this.checkWaitingStatusHandler(this.state.selectedRowKeys, props.action)
            break;
         case OrderStatusActionEnum.REFUSE.toString():
         case OrderStatusActionEnum.PROCESS.toString():
            this.orderStatusHandler(this.state.selectedRowKeys, props.action);
            break;
         case 'export_csv':
            this.exportOrdersHandler()
            break
         case 'export_ria':
            this.exportForRIAHandler()
            break
         case 'export_po_pivot':
            this.exportForPOPivotHandler()
            break
         case 'export_ria_weekly_pivot':
            this.exportForRIAWeeklyPivot()
            break
         case 'download_amazon_updates':
            this.orderDownloadHandler(this.state.selectedRowKeys)
            break
         // case 'create_progressive_for_orders':
         //    selectedOrders = this.state.itemList.filter(x => this.state.selectedRowKeys.includes(x.key))
         //    // console.log(selectedOrders)
         //    _.forEach(selectedOrders, (order, i) => {
         //       console.log('SELECTED ORDER', order, order.vendor_code.type)
         //       if (_.isNil(progressiveType)) {
         //          progressiveType = order.vendor_code.type
         //          progressiveGroup = order.vendor_code.group
         //       } else {
         //          if (progressiveType !== order.vendor_code.type || progressiveGroup !== order.vendor_code.group) {
         //             error = true
         //          }
         //       }
         //    })
         //    if (error) {
         //       notification.error({
         //          message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
         //          description: languageManager.getMessage(this.props.commonState, 'notification.error.orders.differentVendorCodeType'),
         //       })
         //    } else {
         //       const item = {
         //          type: progressiveType,
         //          group: progressiveGroup,
         //          purchase_orders_ids: this.state.selectedRowKeys
         //       }
         //       this.setState({ createProgressiveItem: item, showCreateProgressiveDrawer: true })
         //    }
         //    break
         case 'auto_create_progressives_for_orders':
            let progressiveType = null
            let progressiveCountry = null
            let progressiveGroup = null
            let progressiveVendorCode = null
            let progressiveFCCode = null
            let selectedOrders
            let acceptanceError = false
            let vendorCodeError = false
            let fcCodeError = false
            let vendorCodeTypeError = false
            let genericError = false
            selectedOrders = this.state.itemList.filter(x => this.state.selectedRowKeys.includes(x.key))
            // console.log(selectedOrders)
            _.forEach(selectedOrders, (order, i) => {
               // console.log('SELECTED ORDER', order, order.vendor_code.type)
               if (_.isNil(order.acceptance_date)) {
                  acceptanceError = true
                  return false
               }
               if (_.isNil(progressiveVendorCode)) {
                  progressiveVendorCode = order.vendor_code.id
               } else {
                  if (progressiveVendorCode !== order.vendor_code.id) {
                     vendorCodeError = true
                     return false
                  }
               }
               if (!_.isNil(order.amazon_warehouse) && _.isNil(progressiveFCCode)) {
                  progressiveFCCode = order.amazon_warehouse.id
               } else {
                  if (!_.isNil(order.amazon_warehouse) && progressiveFCCode !== order.amazon_warehouse.id) {
                     fcCodeError = true
                     return false
                  }
               }
               if (_.isNil(progressiveType)) {
                  progressiveType = order.vendor_code.type
                  progressiveCountry = order.marketplace.country_id
                  progressiveGroup = order.vendor_code.group
               } else {
                  if (progressiveType !== order.vendor_code.type || progressiveCountry !== order.marketplace.country_id || progressiveGroup !== order.vendor_code.group) {
                     vendorCodeTypeError = true
                     return false
                  }
               }
            })
            if (acceptanceError) {
               notification.error({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.orders.ordersNotAccepted'),
               })
            } else if (vendorCodeError) {
               notification.error({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.orders.differentVendorCode'),
               })
            } else if (fcCodeError) {
               notification.error({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.orders.differentFCCode'),
               })
            } else if (vendorCodeTypeError) {
               notification.error({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.orders.differentVendorCodeType'),
               })
            } else if (genericError) {
               notification.error({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
               })
            } else {
               this.createProgressivesHandler(progressiveType, progressiveCountry, progressiveGroup, progressiveVendorCode, progressiveFCCode)
            }
            break
         default:
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
            })
            break

      }
   }

   setSearchYear = (year) => {
      this.setState({ searchYear: year });
   }

   handleTableChange = (pagination, filters, sorter, extra) => {
      console.log(pagination, filters, sorter, extra);
      const paginationParams = {
         current_page: pagination.current,
         per_page: pagination.pageSize,
         order_column: !_.isNil(sorter.column) && !_.isNil(sorter.column.key) ? sorter.column.key : null,
         order_direction: !_.isNil(sorter.order) ? sorter.order : null,
      }
      this.getList(paginationParams, this.state.filtersValues);
   };

   submitFilters = (values) => {
      // console.log("Selected filters", values)
      Object.keys(values).forEach(function (key) {
         if (_.isArray(values[key])) {
            _.forEach(values[key], function (value, key, array) {
               if (Moment.isMoment(value)) {
                  array[key] = Moment(value).format('YYYY-MM-DD HH:mm');
               }
            })
         } else if (Moment.isMoment(values[key])) {
            values[key] = Moment(values[key]).format('YYYY-MM-DD HH:mm');
         }
      });
      this.setState({ filtersValues: values, selectedRowKeys: [] })
      this.getList(updateObject(this.state.poPaginationParams, { current_page: 1 }), values);
   }
   resetFilters = () => {
      this.setState({ filtersValues: {}, selectedRowKeys: [] })
      this.getList(updateObject(this.state.poPaginationParams, { current_page: 1 }), {});
   }

   hideCreateProgressiveDrawer = () => {
      this.setState({ showCreateProgressiveDrawer: false })
   }
   handleCreateProgressive = (data) => {
      console.log('Progressivo creato/aggiornato correttamente')
      this.setState({ showCreateProgressiveDrawer: false })
   }

   render() {
      if (this.state.pageLoader) return <Loader />
      const { Title, Text } = Typography
      const { Panel } = Collapse
      const { Option } = Select
      const table_columns = [
         {
            title: 'MP',
            width: 60,
            key: 'marketplace_id',
            fixed: 'left',
            // sorter: (a, b) => a.marketplace.name.localeCompare(b.marketplace.name),
            sorter: true,
            render: (record) => (
               record.marketplace.name
            )
         },
         {
            title: 'Ordine',
            width: 80,
            key: 'purchase_order_number',
            fixed: 'left',
            // sorter: (a, b) => a.purchase_order_number.localeCompare(b.purchase_order_number),
            sorter: true,
            // ...this.getColumnSearchProps('purchase_order_number', 'ordine'),
            render: (record) => (
               // <a href='!#' onClick={(e) => { e.preventDefault(); this.props.history.push('/purchase_orders/details/' + record.id) }}>{record.purchase_order_number}</a>
               // <a href={'/purchase_orders/details/' + record.id}>{record.purchase_order_number}</a>
               <Link
                  to={{
                     pathname: '/purchase_orders/details/' + record.id,
                  }}
               >
                  {record.purchase_order_number}
               </Link>
            )
         },
         // {
         //    title: 'Comparazione',
         //    width: 125,
         //    dataIndex: 'comparison',
         //    key: 'comparison',
         //    fixed: 'left',
         //    render: (record) => (
         //       record.comparison + '%'
         //    ),
         //    sorter: (a, b) => a.comparison - b.comparison,
         // },
         {
            title: 'Fornitore',
            width: 75,
            key: 'vendor',
            fixed: 'left',
            render: (record) => (
               record.vendor
            ),
            // sorter: (a, b) => a.vendor.localeCompare(b.vendor),
            sorter: true,
         },
         {
            title: 'Ordinato il',
            width: 80,
            key: 'order_date',
            render: (record) => (
               <Space direction='vertical' size={0}>
                  <Text>{record.order_date_human}</Text>
                  <Text>{record.order_time_human}</Text>
               </Space>
            ),
            // sorter: (a, b) => a.order_date.localeCompare(b.order_date),
            sorter: true,
         },
         {
            title: 'Aggiornato il',
            width: 80,
            key: 'order_changed_date',
            render: (record) => (
               <Space direction='vertical' size={0}>
                  <Text>{record.order_changed_date_human}</Text>
                  <Text>{record.order_changed_time_human}</Text>
               </Space>
            ),
            // sorter: (a, b) => a.order_changed_date.localeCompare(b.order_changed_date),
            sorter: true,
         },
         // {
         //    title: 'Giorno',
         //    width: 100,
         //    key: 'order_day',
         //    render: (record) => (
         //       record.order_day
         //    ),
         //    sorter: (a, b) => a.order_day.localeCompare(b.order_day),
         // },
         {
            title: 'Spedire a',
            key: 'amazon_warehouse_id',
            width: 70,
            render: (record) => (
               record.amazon_warehouse ? record.amazon_warehouse.fc_code : null
            ),
            // sorter: (a, b) => a.amazon_warehouse.fc_code.localeCompare(b.amazon_warehouse.fc_code),
            ellipsis: true,
            sorter: true,
         },
         // {
         //    title: 'Tipo di finestra',
         //    width: 200,
         //    key: 'shipment_type',
         //    fixed: 'left',
         //    render: (record) => (
         //       record.shipment_type
         //    ),
         //    sorter: (a, b) => a.shipment_type.localeCompare(b.shipment_type),
         //    ellipsis: true,
         // },
         // {
         //    title: 'Finestra',
         //    width: 150,
         //    key: 'shipment_window',
         //    render: (record) => (
         //       Moment(record.shipment_window_start).format('L') + Moment(record.shipment_window_end).format('L')
         //    ),
         //    sorter: (a, b) => a.shipment_window_start.localeCompare(b.shipment_window_start),
         // },
         {
            title: 'Inizio finestra',
            width: 80,
            key: 'shipment_window_start',
            render: (record) => (
               <Space direction='vertical' size={0}>
                  <Text>{record.shipment_window_start_date_human}</Text>
                  <Text>{record.shipment_window_start_time_human}</Text>
               </Space>
            ),
            // sorter: (a, b) => a.shipment_window_start.localeCompare(b.shipment_window_start),
            sorter: true,
         },
         {
            title: 'Fine finestra',
            width: 80,
            key: 'shipment_window_end',
            render: (record) => (
               <Space direction='vertical' size={0}>
                  <Text>{record.shipment_window_end_date_human}</Text>
                  <Text>{record.shipment_window_end_time_human}</Text>
               </Space>
            ),
            // sorter: (a, b) => a.shipment_window_end.localeCompare(b.shipment_window_end),
            sorter: true,
         },
         // {
         //    title: 'Prodotti',
         //    width: 75,
         //    dataIndex: 'order_lines_count',
         //    key: 'order_lines_count',
         //    fixed: 'left',
         //    sorter: (a, b) => a.order_lines_count - b.order_lines_count,
         // },
         {
            title: 'Quantità richiesta',
            width: 75,
            dataIndex: 'actual_ordered_total',
            key: 'actual_ordered_total',
            // sorter: (a, b) => a.items_total - b.items_total,
            sorter: true,
         },
         {
            title: 'Accettati EC',
            width: 75,
            key: 'accepted_total_user',
            dataIndex: 'accepted_total_user',
            // render: (record) => {
            //    return {
            //       props: {
            //          className: record.accepted_total_user != record.accepted_total ? "bg-warning" : '',
            //       },
            //       children: record.accepted_total_user,
            //    };
            // },
            // sorter: (a, b) => a.accepted_quantity - b.accepted_quantity,
            // sorter: true,
         },
         {
            title: 'Confermati EC',
            width: 75,
            key: 'confirmed_total_user',
            dataIndex: 'confirmed_total_user',
            // render: (record) => {
            //    return {
            //       props: {
            //          className: record.confirmed_total_user != record.accepted_total ? "bg-warning" : '',
            //       },
            //       children: record.confirmed_total_user,
            //    };
            // },
            // sorter: (a, b) => a.accepted_quantity - b.accepted_quantity,
            // sorter: true,
         },
         {
            title: 'Accettati AMZ',
            width: 75,
            key: 'accepted_total',
            render: (record) => {
               return {
                  props: {
                     className: record.accepted_total_user != record.accepted_total && record.confirmed_total_user != record.accepted_total ? "bg-danger" :
                        record.confirmed_total_user != record.accepted_total ? 'bg-warning' : '',
                  },
                  children: record.accepted_total,
               };
            },
            // sorter: (a, b) => a.accepted_quantity - b.accepted_quantity,
            sorter: true,
         },
         // {
         //    title: 'Articoli importati',
         //    width: 150,
         //    dataIndex: 'order_lines_count',
         //    key: 'order_lines_count',
         //    fixed: 'left',
         //    sorter: (a, b) => a.order_lines_count - b.order_lines_count,
         // },
         {
            title: 'Tot net',
            width: 100,
            key: 'price_total',
            render: (record) => (
               record.price_total + ' €'
            ),
            // sorter: (a, b) => a.price_total - b.price_total,
            sorter: true,
         },
         {
            title: 'Stato AMZ', width: 130, key: 'po_status',
            render: (record) => (
               <Space direction='vertical' size={0}>
                  <Text>{record.po_status}</Text>
                  <Text>{record.order_details_changed_date_human}</Text>
               </Space>
            ),
            sorter: true,
         },
         {
            title: 'Accettazione AMZ', width: 130, key: 'order_status_changed_date',
            render: (record) => (
               <Space direction='vertical' size={0}>
                  <Text>{record.po_ack_status}</Text>
                  <Text>{record.order_status_changed_date_human}</Text>
               </Space>
            ),
            sorter: true,
         },
         {
            title: 'Accettazione EC', width: 130, key: 'acceptance_date',
            render: (record) => (
               <Space direction='vertical' size={0}>
                  {!_.isNil(record.acceptance_date) ? Moment(record.acceptance_date).format('L LTS') : null}
                  {!_.isNil(record.acceptance_user) ? record.acceptance_user.firstname + ' ' + record.acceptance_user.lastname : null}
               </Space>
            ),
            sorter: true,
            // sorter: (a, b) => a.order_status_name.localeCompare(b.order_status_name),
         },
         {
            title: 'Stato EC', width: 100, key: 'po_status_id',
            render: (record) => (
               record.order_status_name
            ),
            sorter: true,
            // sorter: (a, b) => a.order_status_name.localeCompare(b.order_status_name),
         },
         {
            title: 'P', width: 25, key: 'progressives_created',
            render: (record) => (
               record.progressives_created ? <CheckOutlined className='icon-success' /> : <CloseOutlined className='icon-error' />
            ),
            // sorter: (a, b) => a.order_status_name.localeCompare(b.order_status_name),
         },
         {
            title: 'Azioni',
            key: 'actions',
            width: 150,
            fixed: 'right',
            align: 'right',
            render: (text, record) => (
               <Space>
                  <Link
                     to={{
                        pathname: '/purchase_orders/details/' + record.id,
                     }}
                  >
                     <Button type="success" size='small'
                        icon={<EyeOutlined />}
                     />
                  </Link>
                  {
                     record.order_status && record.order_status.id === 1 ?
                        [
                           <Tooltip key='action_accept' title={languageManager.getMessage(this.props.commonState, 'common.accept_order')}>
                              <Button type="success" size="small"
                                 icon={<CheckOutlined />}
                                 onClick={() => this.orderStatusHandler([record.id], OrderStatusActionEnum.ACCEPT.toString())}
                              />
                           </Tooltip>,
                           <Tooltip key='action_refuse' title={languageManager.getMessage(this.props.commonState, 'common.refuse_order')}>
                              <Button type="danger" size="small"
                                 icon={<CloseOutlined />}
                                 onClick={() => this.orderStatusHandler([record.id], OrderStatusActionEnum.REFUSE.toString())}
                              />
                           </Tooltip>,
                           // <Tooltip key='action_test' title={languageManager.getMessage(this.props.commonState, 'common.test_order')}>
                           //    <Button type="danger" size="small"
                           //       icon={<InfoCircleOutlined />}
                           //       onClick={() => this.orderStatusHandler(record.id, 'TEST')}
                           //    />
                           // </Tooltip>
                        ]
                        : ''
                  }
                  <Button type="warning" size="small"
                     icon={<RetweetOutlined />}
                     loading={this.state.loadersDownload[record.id]}
                     onClick={() => this.orderDownloadHandler([record.id])}
                  />
                  {/* {
                     record.order_status && _.includes([2, 3, 4,], record.order_status.id) ?
                        [
                           <Tooltip key='action_delete' title={languageManager.getMessage(this.props.commonState, 'common.delete_order')}>
                              <Button type="danger" size="small"
                                 icon={<DeleteOutlined />}
                                 onClick={() => this.orderStatusHandler([record.id], OrderStatusActionEnum.REFUSE.toString())}
                              />
                           </Tooltip>,
                        ]
                        : null
                  } */}
                  {/* <Popconfirm
                     placement="topRight"
                     title="Cancelli?"
                     onConfirm={() => this.confirmHandler('deleteItem', record.id)}
                     okText="Yes"
                     cancelText="No"
                  >
                     <Button className="link"><i className="far fa-trash-alt"></i></Button>
                  </Popconfirm> */}
               </Space>),
         },
      ];
      const tableLayout = {
         bordered: true,
         ellipsis: true,
         size: "small",
         scroll: { x: 'true' },
         showSorterTooltip: false,
         loading: this.state.tableLoader,
         pagination: {
            current: this.state.poPaginationParams.current_page,
            pageSize: this.state.poPaginationParams.per_page,
            total: this.state.poPaginationParams.total,
            showSizeChanger: true,
            defaultPageSize: 50,
            size: "default",
            showTotal: total => total + (total > 1 ? ' ordini' : ' ordine')
         },
         onChange: this.handleTableChange
      }

      const { selectedRowKeys } = this.state;
      const rowSelection = {
         selectedRowKeys,
         onChange: this.onSelectChange,
         preserveSelectedRowKeys: true,
      };

      const importResult = (
         <Card title="Card title">
            <Card type="inner" title="Importazione EDI files" extra={<Button className="link">Chiudi</Button>}>
               Importazione EDI files in corso
            </Card>
         </Card>
      );

      const ordersTable = (
         <Table
            {...tableLayout}
            columns={table_columns}
            rowSelection={rowSelection}
            rowKey={(record) => (record.id)}
            dataSource={this.state.itemList}

         // rowClassName={(record, index) => {
         //    record.order_day==='Monday' % 2 === 0 ? 'table-row-light' :  'table-row-dark'
         // }}
         />
      )

      const pageFilters = (
         <Collapse defaultActiveKey={['filters']}>
            <Panel header="Filtri" key="filters">
               <TableFilters submitFilters={this.submitFilters} resetFilters={this.resetFilters} filters={this.state.filters} />
            </Panel>
         </Collapse>
      );

      // const apiFeedback = (
      //       <Alert
      //          closable
      //          message="Dettagli importazione"
      //          type="info"
      //          description={<List
      //             size="small"
      //             dataSource={this.state.response.details}
      //             renderItem={item => <List.Item className='padding-0-I'>{item}</List.Item>}
      //          />}
      //          showIcon
      //       />
      // )

      const drawerEditProgressive = (
         <DrawerEditProgressive
            key='createDrawer'
            visible={this.state.showCreateProgressiveDrawer}
            item={this.state.createProgressiveItem}
            complements={this.state.progressivesComplements}
            onClose={this.hideCreateProgressiveDrawer}
            onSubmit={(data) => this.handleCreateProgressive(data)} />
      )

      const hasSelected = selectedRowKeys.length > 0;
      const singleSelected = selectedRowKeys.length > 1;
      const pageContent = (
         <div>
            <div className="align-items-center row" style={{ marginTop: 15, marginBottom: 5 }}>
               <div className="col-sm-6">
                  <div className="d-none d-md-block">
                     <Space>
                        <Button
                           onClick={() => this.selectAllHandler()}
                        >{this.state.selectedAll ? 'Deseleziona' : 'Seleziona tutto'}
                        </Button>
                        <span>
                           {hasSelected ? singleSelected ? `${selectedRowKeys.length} elementi selezionati` : `${selectedRowKeys.length} elemento selezionato` : ''}
                        </span>
                        <DropdownMenu
                           type='primary'
                           title='Azioni multiple'
                           action={this.multipleActionHandler}
                           values={this.state.multipleActionList}
                           disabled={!hasSelected}
                           loading={this.state.multipleActionLoader}
                        />
                        <DropdownMenu
                           type='primary'
                           title='Esporta'
                           action={this.multipleActionHandler}
                           values={this.state.multipleExportList}
                           disabled={!hasSelected}
                           loading={this.state.multipleExportLoader}
                        />
                     </Space>
                  </div>
               </div>
               <div className="col-sm-6">
                  <div className="float-right d-none d-md-block">
                     <Space>
                        <Button icon={<RetweetOutlined />} onClick={this.updateList} loading={this.state.tableLoader}>
                           {languageManager.getMessage(this.props.commonState, 'common.refresh_data')}
                        </Button>
                     </Space>
                  </div>
               </div>
            </div>
            {ordersTable}
            {/* {drawerEditProgressive} */}
         </div >
      )

      const pageWrapper = (
         <div className="card">
            <div className="card-header">
               <Row gutter={16} align='middle'>
                  <Col>
                     <Title level={5}>{languageManager.getMessage(this.props.commonState, 'component.purchase_orders.title')}</Title>
                  </Col>
                  <Col flex='auto'></Col>
                  <Col>
                     <Space>
                        <Button
                           type='primary'
                           icon={<DownloadOutlined />}
                           onClick={() => this.importNewFromApi()}
                           loading={this.state.loaders.downloadAPI}
                        >
                           Scarica nuovi ordini
                        </Button>
                        {/* <Button
                           type='primary'
                           icon={<DownloadOutlined />}
                           onClick={() => this.importEdiFromAmazon()}
                           loading={this.state.loaders.downloadEDI}
                        >
                           Scarica EDI Amazon
                        </Button> */}
                        <Link to={{ pathname: "/purchase_orders/import/" }} >
                           <Button
                              type='primary'
                              icon={<UploadOutlined />}
                           >Importa ordini da CSV</Button>
                        </Link>
                        {
                           !_.isNil(this.props.authState.userData.languages) ? (

                              <Select
                                 showSearch
                                 className="width-70px-I"
                                 placeholder="Seleziona la lingua"
                                 defaultValue={this.state.componentLanguage}
                                 optionFilterProp="children"
                                 onChange={this.setDefaultLanguageHandler}
                              >
                                 {
                                    this.props.authState.userData.languages.map((lang) => {
                                       return <Option key={lang.value} value={lang.value}>{lang.label}</Option>
                                    })
                                 }
                              </Select>
                           ) : null
                        }
                     </Space>
                  </Col>
               </Row>
            </div>
            {this.state.importBox ? importResult : null}
            <div className="card-body">
               <Row>
                  <Col span={24}>{pageFilters}</Col>
               </Row>
               {pageContent}
            </div>
            <div className="card-footer">
               <Row gutter={16}>
                  <Col>
                     <Space size='small'>
                        <Link to={{ pathname: "/" }} >
                           <Button icon={<DoubleLeftOutlined />}>
                              {languageManager.getMessage(this.props.commonState, 'common.back')}
                           </Button>
                        </Link>
                     </Space>
                  </Col>
                  <Col flex='auto'></Col>
                  <Col></Col>
               </Row>
            </div>
         </div>
      );

      const helpButton = {
         title: "Aiuto"
      }

      const buttons = [];
      buttons.push(helpButton);

      return (
         <div className="container-fluid">
            <div className="row">
               <div className="col">
                  {pageWrapper}
               </div>
            </div>
         </div>
      );
   }
}

const mapStateToProps = state => {
   return {
      authState: state.authenticationState,
      commonState: state.commonState,
   };
};

// const mapDispatchToProps = dispatch => {
//     return {
//         onFetchTaxRates: () => dispatch( actions.getTaxRatesList() )

//     };
// };

export default connect(mapStateToProps)(PurchaseOrdersList);